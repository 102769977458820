export {
    ids
};

const ids = {
    banner: 'sln-banner',
    header: 'sln-header',
    footer: 'sln-footer',
    styles: 'sln-styles'
};

