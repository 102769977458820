import { languageMap as languages } from '@maersk/shared-js';

import { has } from './helpers';
import { getCookie, setCookie } from './cookie';

export {
  getLanguage,
  setLanguage,
  languages,
  language
};

const cookieName = 'selectedLang';
const cookieDuration = 365;

/**
 * gets the default language for fallbacks
 */
const getDefaultLanguage = () => {
  return languages.en;
};

/**
 * Gets the set language or returns the default language if it doesn't match one we support
 */
const getLanguage = () => {
  return has(languages, getCookie(cookieName)) ? getCookie(cookieName) : getDefaultLanguage();
};

/**
 * Set the language
 */
const setLanguage = (nextLanguage) => {
  setCookie(cookieName, has(languages, nextLanguage) ? nextLanguage : getDefaultLanguage(), cookieDuration);
};

const language = getLanguage();
