import { getItemRaw } from './local-storage-adapter';
import { has } from './helpers';
import { sessionKey } from '../constants/local-storage';

export {
  user,
  getUser,
  getUserKey
};

/** get decoded user object if available */
const getUser = () => {
  const auth = getItemRaw(sessionKey);

  if (auth === 'true') {
    return {
      authenticated: true,
    };
  }

  return {
    authenticated: false
  };
};

const user = getUser();

/** get a key from user object or return key */
const getUserKey = (key, obj = user) => {
  return has(obj, key) ? obj[key] : key;
};
