import { startHeader, destroyHeader, closeAllMenus, openPanel } from './header/header';
import { startFooter, destroyFooter } from './footer/footer';
import { addEvent } from './utils/events';
import { getConfig } from './utils/config';

export {
  start,
  reload,
  destroy
};

/** pull in brand specific styling */
require(`./../scss/${process.env.BRAND}.scss`);

/** Start SLN */
const start = () => {
  startHeader();
  startFooter();
};

const reload = () => {
  start();
};

const destroy = () => {
  destroyFooter();
  destroyHeader();
};

if(getConfig().autostart) {
  addEvent(document, 'DOMContentLoaded', start);
}

window.sln = {
  reload,
  start,
  destroy,
  closeAllMenus,
  openPanel
};
