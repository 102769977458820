export function setCookie(name, value, days, secure = false) {
  let expires;
  let secured='';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toGMTString()}`;
  }
  else {
    expires = '';
  }

  if (document.location.protocol === 'https:' && secure) {
    secured = ';secure';
  }

  document.cookie = `${name}=${value}${expires};path=/${secured}`;
}

export function getCookie(name) {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(name + '=');
    if (start != -1) {
      start = start + name.length + 1;
      let end = document.cookie.indexOf(';', start);
      if (end == -1) {
        end = document.cookie.length;
      }
      return decodeURI(document.cookie.substring(start, end));
    }
  }
  return '';
}
