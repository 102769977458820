export {
  addEvent,
  removeEvent,
  emitEvent,
  events
};

const events = {
  end: 'SLN-Loaded',
  elemClosing: 'SLN-Elem-Closing',
  elemClosed: 'SLN-Elem-Closed',
  elemOpening: 'SLN-Elem-Opening',
  elemOpened: 'SLN-Elem-Opened',
  subnavClosed: 'SLN-SubNav-Closed',
  subnavOpened: 'SLN-SubNav-Opened',
  consentUpdated: 'SLN-Consent-Updated',
  show: 'SLN-Showing',
  hide: 'SLN-Hiding'
};

/**
 * Add event to a dom element
 * @param {dom} el the dom element 
 * @param {string} event to add
 * @param {*} fnc to call
 */
const addEvent = (el, event, fnc) => {
  if(el) {
    el.addEventListener(event, fnc);
  }
};

/**
 * Remove event from a dom element
 * @param {dom} el the dom element 
 * @param {string} event to remove
 * @param {*} fnc to remove
 */
const removeEvent = (el, event, fnc) => {
  if(el) {
    el.removeEventListener(event, fnc);
  }
};

/**
 * emit a custom event others may be listening for
 * @param {string} type event name 
 * @param {*} data payload
 */
const emitEvent = (name, data = undefined) => {
  let loadedEvent;
  let et = name;

  if (!et) return;
  if (typeof window.CustomEvent === 'function') {
    loadedEvent = new CustomEvent(et, { data });
  } else {
    loadedEvent = document.createEvent('CustomEvent');
    loadedEvent.initCustomEvent(et, true, true, data);
  }
  
  document.dispatchEvent(loadedEvent);
};
