import { getCookie, setCookie } from './cookie';
import { emitEvent, events } from './events';
import injectFile from './inject-file';
import { language } from './language';

export {
  startCookieConsent,
  getCookieConsent
};

const CONSENT = {
  cookieName: 'CookieInformationConsent',
  cookieErrorName: 'CookieInformationConsentError',
  cookieCategories: [{
    name: 'cookie_cat_necessary',
    normalised: 'necessary'
  }, {
    name: 'cookie_cat_functional',
    normalised: 'functional'
  }, {
    name: 'cookie_cat_statistic',
    normalised: 'statistical'
  }, {
    name: 'cookie_cat_marketing',
    normalised: 'marketing'
  }, {
    name: 'cookie_cat_unclassified',
    normalised: 'unclassified'
  }]
};

/**
 * does category have consent
 * @param {string} category name 
 * @param {array} approved consent categories
 */
const isCategoryApproved = (name, arr = []) => {
  return arr.includes(name);
};

/**
 * parse the consent cookie and return normalised results 
* */ 
const getCookieConsent = (error = false) => {
  const cookies = getCookie(CONSENT.cookieName);
  let consent = {};

  if(cookies) {
    const data = JSON.parse(decodeURIComponent(cookies));
    const approved = data.consents_approved;

    CONSENT.cookieCategories.forEach(category => {
      consent[category.normalised] = isCategoryApproved(category.name, approved);
    });
  }

  if(error) {
    consent.connectionError = true;
  }

  return Object.keys(consent).length === 0 ? cookies : consent;
};

const fireUpdate = () => {
  const data = getCookieConsent();

  emitEvent(events.consentUpdated, data);
};

/**
 * listen for the cookie information consent change event, then trigger out own
 */
const bindConsentChangeEvent = () => {
  window.addEventListener('CookieInformationConsentGiven', fireUpdate);
};

/**
 * 
 * error handler for cookie information library request
 */
const handleConsentInjectionError = (err) => {
  console.warn('Cookie consent error', err);
  setCookie(CONSENT.cookieErrorName, true,  365);
  emitEvent(events.consentUpdated, getCookieConsent(true));
  updateDataLayer('IGN_Loaded');
};

const handleConsentInjectionSuccess = () => {
  setCookie(CONSENT.cookieErrorName, false,  365);
  updateDataLayer('IGN_Loaded');
};

/**
 * push cookie consent to GTM dataLayer
 */
const updateDataLayer = (event) => {  
  if(window.dataLayer) {
    window.dataLayer.push({
      event: event
    });
  }
};

/** inject the cookie consent for maersk only **/ 
const startCookieConsent = () => {
  let lang = language.toUpperCase().substring(0, 2);

  bindConsentChangeEvent();
  checkConsent();
  
  // catch a mismatch between us and cookieinformation language codes
  const attributes = [{
    name: 'data-culture',
    value: lang === 'JP' ? 'JA' : lang
  }];

  injectFile('script', 'https://policy.app.cookieinformation.com/uc.js', handleConsentInjectionSuccess, handleConsentInjectionError, attributes);
};


/**
 * check the consent cookie is not set to true
 * https://jira.maerskdev.net/browse/BMW-363
 */
const checkConsent = () => {
  const cookies = getCookie(CONSENT.cookieName);

  if(cookies === 'true') {
    setCookie(CONSENT.cookieName, false);
  }
};
