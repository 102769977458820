import { has, renderProp, createEl } from '../utils/helpers';
import { linkFilter, linkTemplate } from '../utils/link';
import { logoTemplate } from '../header/header.tmpl';

export {
  template2,
  socialTemplate,
  columnsTemplate
};


/**
 * social media link template
 * @param {array} links - list of social links 
 */
const socialTemplate = (links) => `
  <div class="sln-footer__social">
  ${links
    .filter(linkFilter)
    .map((link) => `
    ${linkTemplate(link, 'sln-footer__social__link')}
  `).join('')}
  </div>`
  ;

/**
 * footer list link columns
 * @param {array} array of columns with array of links
 */
const columnsTemplate = (columns) => `
  <div class="sln-footer__links">
  ${columns
    .map((column) => `
    <div class="sln-footer__links__column">
      ${column.links
        .filter(linkFilter)
        .map((link) => `
        ${linkTemplate(link, 'sln-footer__link')}
      `).join('')}
    </div><!-- /links -->
  `).join('')}
  </div>`
  ;

const template2 = (data) => {
  const footer = createEl('div', {
    classes: 'sln-footer',
    id: 'sln-footer'
  });

  footer.innerHTML = `
    <div class="sln-footer__container">
      <div class="sln-footer__links"></div>
      <div class="sln-footer__base">
        <div class="sln-footer__copyright">
          <div class="sln-footer__copyright__text">
            ${renderProp(data, 'copyright')}
          </div>
        </div>
        ${has(data, 'social.links') ? socialTemplate(data.social.links) : ''}
      </div><!-- /base -->
    </div><!-- /container -->`
    ;


  const links = footer.querySelector('.sln-footer__links');
  const copyright = footer.querySelector('.sln-footer__copyright');

  copyright.insertAdjacentElement('afterbegin', logoTemplate('sln-footer__logo'));

  if(data.columns) {
    data.columns.forEach(column => {
      let columnEl = createEl('div', {
        classes: 'sln-footer__links__column'
      });

      column.links.filter(linkFilter).forEach(link => {
        const a = createEl('a', {
          classes: 'sln-footer__link',
          html: link.title,
          ...link
        });
        columnEl.appendChild(a);
      });

      links.appendChild(columnEl);
    });
  }

  return footer;
};

