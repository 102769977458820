/**
 * Injects a CSS or JS file
 * @param {string} type script or css
 * @param {string} src the URI of the source file to load
 * @param {function} callback Callback to be executed on successful load
 * @param {function} error Callback to be executed error
 * @param {array} attributes Optional attributes to be added
*/

export default function (type, src, callback, error, attributes = []) {
  const s = 'script';
  let elem = document.createElement(type === s ? type : 'link');

  elem.type = 'text/' + (type === s ? 'javascript' : 'css');

  if (type === s) {
    elem.src = src;
  } else {
    elem.href = src;
    elem.rel = 'stylesheet';
  }

  attributes.forEach(attr => {
    elem.setAttribute(attr.name, attr.value);
  });

  if(typeof callback === 'function') {
    elem.onload = callback;
  }
  if(typeof error === 'function') {
    elem.onerror = error;
  }

  document.head.appendChild(elem);
}
