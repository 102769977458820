import { replacer } from '../utils/helpers';
import { getData } from '../utils/rest';
import { getConfig } from '../utils/config';

import { template2 } from './footer.tmpl';
import { startCookieConsent } from '../utils/consent';
import { ids } from '../constants/ids';

export {
  startFooter,
  destroyFooter,
  inject
};

/**
 * get the footer data and inject it on the page if all ok
 */
const startFooter = () => {
  const content = getConfig().footer;
  const type = typeof content;

  if (type === 'string') {
    getData(replacer(content))
      .then(data => {
        if (data) {
          inject(data);
        }
      }).catch((err) => {
        console.error('ign-footer error');
        console.error(err);
      });

  } else if (type === 'object') {
    inject(content);
  }
  else {
    console.error('footer type error');
  }

  startCookieConsent();
};

const destroyFooter = () => {
  const footer = document.getElementById(ids.footer);

  if (footer) {
    footer.parentNode.removeChild(footer);
  }
};

/** inject the footer at the end of the page */
const inject = (data, t = template2) => {
  // check for existing footer
  destroyFooter();
  document.body.appendChild(t(data));
};


