import 'whatwg-fetch';

import { getItem, setItem } from '../helpers/session-storage-adapter';
// import { language } from './language';
import { getVersion } from './helpers';
// import { tags } from './config';

export {
  get,
  getData,
  getKey,
  checkStorage,
  getBanner
};

/** generate the key for the session storage */
const getKey = (prefix) => {
  return `${prefix}-${getVersion()}-en`;
};

/** check to see if the data we need already resides in session storage */
const checkStorage = (prefix) => {
  return getItem(getKey(prefix));
};

/**
 * fetch or retreive data
 * @param {string} url for fetching 
 * @param {*} prefix used for retreiving local-storage e.g. header, footer
 */
const getData = (url, prefix) => {
  if(prefix){
    const data = checkStorage(prefix);

    // if the local storage key exists, return that data
    if(data) {
      return Promise.resolve(data);
    }
    
    // no data found so make a request
    return get(url, prefix);
  }

  // force through the request
  return get(url);
};

/**
 * fetches data for the sln and handles network error and unexpected responses
 * @param {string} url path to the endpoint
 */
const get = (url, prefix) => {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    })
    .then(response => {
      if(prefix) {
       setItem(getKey(prefix), response);
      }

      return response;
    });
};

/**
 * temporary function to fetch banner only from sc8 json
 */
const getBanner = (url) => {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    });
};
