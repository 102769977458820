import { linkTemplate } from '../utils/link';
import { createEl } from '../utils/helpers';

export {
  promoTemplate
};

/** promotional spot template */
const promoTemplate = (data, delay = 1) => {
  const promo = createEl('div', {
    classes: `sln-header__promo sln-delay sln-delay--${delay}`,
    html: `${data.img ? `<img src="${data.img.src}" alt="${data.img.alt}" class="sln-header__promo__img" loading="lazy" />` : ''}
    
    <h4 class="sln-header__promo__title">${data.title}</h4>
    <p class="sln-header__promo__text">${data.text}</p>
    ${data.button ? linkTemplate(data.button, `sln-button ${data.button.type ? `sln-button--${data.button.type}` : ''}`) : ''}`
  });

  return promo;
};
