export {
  setLabels,
  labels
};

let labels = {};

const setLabels = (data) => {
  if(data) {
    labels = data;
  }
};
